import { register } from 'swiper/element/bundle';
register();

// Custom styles to inject into the web component, because we can only control exposed ::part() elements from outside.
	const params = {
		// array with CSS styles
		injectStyles: [
			`
				.swiper-button-next svg {
					display: none;
				}
				.swiper-button-prev svg {
					display: none;
				}
				.swiper-lazy-preloader {
					display: none !important; opacity: 0;
				}
			`,
		],
		autoplay: {
			delay: 5000
		}

		// array with CSS urls
		// injectStylesUrls: ['path/to/one.css', 'path/to/two.css'],
	};

	const swiperElements = document.querySelectorAll('swiper-container[init="false"]');
	console.log(`We ran and we found ${swiperElements.length} swiper elements that need custom styles.`);
	swiperElements.forEach( swiperEl => {
		Object.assign(swiperEl, params);
		swiperEl.initialize();
	});
